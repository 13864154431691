<div
  *ngIf="newFormOpen"
  class="mb-5"
>
  <div class="row align-items-center">
    <div class="col">
      <h2
        class="d-inline-block"
        i18n="@@createNewTicket"
      >Neues Ticket erstellen</h2>
    </div>
    <div class="col-auto">
      <div
        class="btn btn-ghost-primary text-primary rounded-circle"
        (click)="this.newFormOpen = false"
      ><i class="ri-close-line ri-2x"></i></div>
    </div>
  </div>
  <div
    class="card border-0 shadow"
    *ngIf="this.newServiceQuestion"
  >
    <div class="row mx-0">
      <div class="col-9">
        <div class="card-body p-5">
          <div
            class="row mb-3"
            *ngIf="this.contingents.length > 0"
          >
            <div class="col-1">
              <span class="badge rounded-circle bg-primary fs-6">1</span>
            </div>
            <div class="col-11">
              <span
                class="fs-5 fw-bold"
                i18n="@@serviceChooseContingent"
              >Kontingent wählen</span>
              <div
                [ngClass]="{'border-danger': newFormInvalid && this.newServiceQuestion.serviceQuestion.contingentId == undefined}"
              >
                <div
                  *ngFor="let vkc of this.faqService.activeVkcs"
                  class="btn my-1 me-2"
                  [ngClass]="{
            'btn-primary': this.newServiceQuestion.serviceQuestion.contingentId == vkc.crm_id,
            'btn-light': this.newServiceQuestion.serviceQuestion.contingentId != vkc.crm_id,
          }"
                  (click)="this.newServiceQuestion.serviceQuestion.contingentId = vkc.crm_id"
                >
                  <app-vkc-display [vkc]="vkc"></app-vkc-display>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-1">
              <span class="badge rounded-circle bg-primary fs-6">{{this.contingents.length > 0 ? 2 : 1}}</span>
            </div>
            <div class="col-11">
              <span
                class="fs-5 fw-bold"
                i18n="@@serviceChooseCategory"
              >Kategorie wählen</span>
              <div>
                <ng-select
                  [ngClass]="{'is-invalid': newFormInvalid && this.newServiceQuestion.serviceQuestion.otrsQId == undefined}"
                  [(ngModel)]="this.newServiceQuestion.serviceQuestion.otrsQId"
                  placeholder="Bitte wählen"
                >

                  <ng-option [value]="-1">
                    <span>Bitte Wählen</span>
                  </ng-option>
                  <ng-option
                    *ngFor="let otrsId of this.otrsIds"
                    [value]="otrsId.value"
                  >
                    <span *ngIf="otrsId.key != 'ADOBECAPTIVATE' && otrsId.key != 'AIENGINE' ">{{otrsId.key.slice(0, 1) +
                      otrsId.key.slice(1).toLowerCase()}}</span>
                    <span *ngIf="otrsId.key == 'ADOBECAPTIVATE'">Adobe Learning Manager</span>
                    <span *ngIf="otrsId.key == 'AIENGINE'">Reflact AI-Engine</span>
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-1">
              <span class="badge rounded-circle bg-primary fs-6">{{this.contingents.length > 0 ? 3 : 2}}</span>
            </div>
            <div class="col-11">
              <span
                class="fs-5 fw-bold"
                i18n="@@serviceFormulateRequest"
              >Anfrage Formulieren</span>
              <input
                [ngClass]="{'is-invalid': newFormInvalid && (this.newServiceQuestion.serviceQuestion.title == undefined || this.newServiceQuestion.serviceQuestion.title == '')}"
                class="form-control mb-3"
                placeholder="Betreff"
                [(ngModel)]="this.newServiceQuestion.serviceQuestion.title"
              >
              <div
                class="NgxEditor__Wrapper"
                [ngClass]="{'border-danger': newFormInvalid && (this.newServiceQuestion.serviceQuestion.body == undefined || this.newServiceQuestion.serviceQuestion.body == '')}"
              >
                <ngx-editor-menu
                  [editor]="editor"
                  [toolbar]="toolbar"
                > </ngx-editor-menu>
                <ngx-editor
                  style="min-height: 200px; overflow-x: auto;"
                  [editor]="editor"
                  [(ngModel)]="editorInput"
                  [disabled]="false"
                ></ngx-editor>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-1">
            </div>
            <div class="col-11">
              <div
                
                class="btn btn-primary"
                (click)="createServiceQuestion()"
              ><i class="ri-coupon-2-fill"></i><span i18n="@@serviceCreate"> erstellen</span> </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-3 p-0 d-none d-md-block "
        style="background-color: #E9ECEF;"
      >
        <h5
          class="m-3 position-absolute"
          i18n="@@serviceSelectedContingent"
        >Gewähltes Kontingent:</h5>
        <div
          class="d-flex h-100 align-items-center p-3 justify-content-center"
          *ngIf="contingentMap.has(this.newServiceQuestion.serviceQuestion.contingentId)"
        >
          <ng-container *ngVar="contingentMap.get(this.newServiceQuestion.serviceQuestion.contingentId); let vkc">
            <div
              class="position-relative rounded-circle fs-6 fw-bold text-center"
              style="z-index: 100;"
            >
              {{getTrackedHours(vkc) |
              number:'1.1-1'}} /
              {{vkc.supportcontingent/60 |
              number:'1.1-1'}} <span i18n="@@serviceHours">Std.</span><br><span
                i18n="@@serviceRemaning">verbraucht</span>
            </div>
            <app-simple-pie
              class="position-absolute"
              style="max-width:215px"
              color="#ff00ff"
              [diameter]="200"
              [autosize]="true"
              backgroundColor="#ccc"
              [middleDot]="true"
              middleDotColor="#E9ECEF"
              [percent]="getPercentage(vkc)"
            >
            </app-simple-pie>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<lib-rag-datasupply-grid
  [service]="frontendService"
  [template]="serviceTile"
  [templateFirstItem]="firstTile"
>
</lib-rag-datasupply-grid>

<h1
  *ngIf="this.serviceQuestions.length == 0"
  class="mt-5"
>Noch ganz schön leer hier, erstellen Sie jetzt ihr erstes Ticket.</h1>

<ng-template
  #serviceTile
  let-data="data"
>
  <div class="card shadow border-0">
    <div class="card-body text-center">
      <i
        class="ri-coupon-2-fill ri-5x big-ticket"
        style="color: #00000010;"
      ></i>
      <h3>{{data.title}}</h3>
      <p
        class="small"
        i18n="@@serviceTicket"
      >Ticket #{{data.otrsTicketId}}</p>
      <p class="small m-0"><span i18n="@@serviceCategory">Bereich:</span> {{this.otrsIdsMap.get(data.otrsQId)}}</p>
      <p
        class="small"
        *ngIf="this.contingentMap.get(data.contingentId)"
      ><span i18n="@@serviceContingent">Kontingent:</span>
        <app-vkc-display
          class="small"
          [diameter]="12"
          [vkc]="this.contingentMap.get(data.contingentId)!"
        ></app-vkc-display>
      </p>
      <p>
        <span class="badge bg-light text-primary">
          {{this.ortsTicketMap.get(data.otrsTicketId)?.ticket?.State}}
        </span>
      </p>
      <p>
        <span
          class="btn btn-light btn-sm text-primary border-primary small rounded-5"
          (click)="overlay.setSelected(data)"
        >Details</span>
      </p>
    </div>
  </div>
</ng-template>
<ng-template #firstTile>
  <div
    class="btn btn-outline-secondary bg-light d-flex shadow"
    (click)="this.newFormOpen = true"
  >
    <i class="ri-add-line ri-5x align-self-center m-auto text-primary"></i>
  </div>
</ng-template>


<rag-overlay-aside #overlay>
  <div
    header
    *ngIf="overlay.selectedObject"
  >
    <ng-container *ngVar="this.ortsTicketMap.get(overlay.selectedObject.otrsTicketId); let otrsTicket">
      <h1>{{overlay.selectedObject.title}}</h1>
      <p>Ticket #{{overlay.selectedObject.otrsTicketId}}&nbsp;&nbsp;&nbsp;&nbsp;<span
          i18n="@@serviceCategory">Bereich:</span>
        {{this.otrsIdsMap.get(overlay.selectedObject.otrsQId)}}&nbsp;&nbsp;&nbsp;&nbsp;
        <span
          class="badge "
          [ngClass]="{
        'bg-success': otrsTicket.ticket.State == 'closed',
        'bg-info': otrsTicket.ticket.State == 'open',
        'bg-primary': otrsTicket.ticket.State != 'closed' && otrsTicket.ticket.State != 'open'
      }"
        >{{otrsTicket.ticket.State}}</span>
      </p>
    </ng-container>
  </div>
  <ng-container *ngIf="overlay.selectedObject">
    <ng-container *ngVar="this.ortsTicketMap.get(overlay.selectedObject.otrsTicketId); let otrsTicket">
      <div
        class="mb-5"
        *ngFor="let article of otrsTicket.ticket.Article; let i = index"
      >
        <app-service-message
          [article]="article"
          [articleNumber]="otrsTicket.ticket.Article.length - (i)"
          [messageType]="(article.SenderType == 'customer' || article.ArticleNumber == 1) ? 'outgoing' : 'incoming'"
          [opened]="article.ArticleNumber == this.openedArticle"
          (clicked)="this.openedArticle = $event"
        ></app-service-message>
      </div>
    </ng-container>
  </ng-container>
</rag-overlay-aside>